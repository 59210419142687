import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import Transition from "../../shared/helper/transitionDialog";
import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  TextField,
  Typography,
} from "@mui/material";
import { Button } from "@mui/material";

const EditModal = ({
  typeModal,
  data,
  open,
  onClose,
  onSave,
  providersData,
  validateDuplicateName,
}) => {
  const [name, setName] = useState("");
  const [provider, setProvider] = useState("");
  const [id, setId] = useState("");
  const [originalId, setOriginalId] = useState("");
  const [errors, setErrors] = useState({});
  const [dirty, setDirty] = useState({});
  const { t } = useTranslation("transfersTable");

  useEffect(() => {
    if (typeModal !== "new") {
      setName(data.name);
      setProvider(data.provider);
      setId(data.id);
      setOriginalId(data._id);
      setDirty({
        name: true,
        id: true,
      });
    } else {
      setName("");
      setId("");
      setProvider("");
      setDirty({});
      setOriginalId("");
    }
  }, [data, typeModal]);

  const validate = useCallback(() => {
    const temp = {};
    temp.name = name ? "" : t("modalErrorNameRequiredCampaign");
    temp.id = id ? "" : t("modalErrorIdRequiredCampaign");
    if (!dirty.name && name !== "") {
      setDirty({ ...dirty, name: true });
    }
    if (!dirty.id && id !== "") {
      setDirty({ ...dirty, id: true });
    }
    if (validateDuplicateName(name, originalId)) {
      temp.name = t("modalErrorNameDuplicateCampaign");
    }

    setErrors(temp);
  }, [dirty, name, id, t]);

  useEffect(() => {
    validate();
  }, [name, provider, id, validate]);

  const hasErrors = useMemo(
    () => Object.keys(errors).some((x) => errors[x] !== ""),
    [errors],
  );

  const handleSave = useCallback(() => {
    onSave({ originalId, name, id, provider });
  }, [name, onSave, provider, id]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      TransitionComponent={Transition}
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "0.5rem",
        },
      }}>
      <Typography variant="h6" gutterBottom sx={{ padding: "1rem" }}>
        {typeModal === "new"
          ? t("modalTitleNewCampaign")
          : t("modalTitleEditCampaign")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          padding: "1rem",
        }}>
        <TextField
          id="name-modal-campaign"
          variant="standard"
          label={t("nameCampaign") + "*"}
          autoFocus
          sx={{ width: "75%", marginBottom: "2rem", marginTop: "2rem" }}
          value={name}
          error={errors.name && dirty.name}
          helperText={errors.name && dirty.name ? errors.name : ""}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        <TextField
          id="id-modal-campaign"
          variant="standard"
          label={t("idCampaign") + "*"}
          sx={{ width: "75%", marginBottom: "2rem" }}
          value={id}
          error={errors.id && dirty.id}
          helperText={errors.id && dirty.id ? errors.id : ""}
          onChange={(e) => {
            setId(e.target.value);
          }}
        />
        <Autocomplete
          id="autocomplete-provider-modal-campaign"
          variant="standard"
          options={providersData}
          getOptionLabel={(option) => option.name}
          value={providersData.find((x) => x.value === provider)}
          sx={{ width: "75%", marginTop: "2rem", marginBottom: "2rem" }}
          onChange={(event, newValue) => {
            setProvider(newValue.value);
          }}
          renderInput={(params) => (
            <TextField
              id="provider-modal-campaign"
              variant="standard"
              {...params}
              label={t("providerCampaign")}
            />
          )}
        />
      </Box>
      <DialogActions>
        <Button
          id="cancel-button-campaign"
          onClick={onClose}
          variant="contained"
          color="error">
          {t("modalCancel")}
        </Button>
        <Button
          id="save-button-campaign"
          variant="contained"
          color="primary"
          disabled={hasErrors}
          onClick={handleSave}>
          {t("modalSave")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditModal;
