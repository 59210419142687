import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { deepPurple } from "@mui/material/colors";

import {
  AppBar,
  Typography,
  Toolbar,
  IconButton,
  ListItemIcon,
  ListItemText,
  ListItem,
  List,
  SwipeableDrawer,
  Select,
  Chip,
} from "@mui/material";

import {
  Menu as MenuIcon,
  Home as HomeIcon,
  Folder as FilesIcon,
  HelpOutline as HelpIcon,
  ExitToApp as ExitToAppIcon,
  ShowChart as ShowChartIcon,
} from "@mui/icons-material";
import PaletteIcon from "@mui/icons-material/Palette";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import GroupIcon from "@mui/icons-material/Group";
import SettingsIcon from "@mui/icons-material/Settings";
import ComputerIcon from "@mui/icons-material/Computer";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import BlurOffOutlinedIcon from "@mui/icons-material/BlurOffOutlined";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SchemaIcon from "@mui/icons-material/Schema";
import SmartToy from "@mui/icons-material/SmartToy";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";

import TranslateIcon from "@mui/icons-material/Translate";
import { setClient } from "../redux/clientSlice";
import { updateClientList } from "../redux/clientSlice";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { get } from "../http/httpService";
import { orderAlphabeticallyWithAttribute } from "../../shared/helper/orderAlphabetically";

const settings = ["preferences", "logout"];

const MyAppbar = (props) => {
  const [left, setLeft] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [clients, setClients] = React.useState([]);
  const [clientSelected, setClientSelected] = React.useState("");
  const navigate = useNavigate();
  const [userPermissions, setUserPermissions] = React.useState([]);
  const [renderComboClient, setRenderComboClient] = React.useState(false);

  let auth = useSelector((state) => state.auth);
  let myTheme = useTheme();
  let client = useSelector((state) => state.client);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const getUserPermisions = () => {
    let user = JSON.parse(atob(auth.token.split(".")[1]));
    setUserPermissions(user.permissions);
    setRenderComboClient(
      user.type === "User" && user.clients !== null && user.clients.length === 1
        ? false
        : true,
    );
  };

  //oninit
  React.useEffect(() => {
    getClientList();
    getUserPermisions();
  }, []);

  useEffect(() => {
    if (auth.name) {
      setName(auth.name);
    }
    if (client.display_name) {
      setClientSelected(client.display_name);
    }
  }, [auth]);

  useEffect(() => {
    if (client.updateCombo) {
      getClientList();
      dispatch(updateClientList(false));
    }
  }, [client]);

  const handleMenu = (event) => {
    setIsOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setIsOpen(false);
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setIsOpen(false);
    setAnchorEl(null);
    props.logout();
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setLeft(open);
  };

  const getClientList = () => {
    get("/clients/names")
      .then(async (data) => {
        if (!auth.root) {
          let newData = [];
          data.forEach((client) => {
            auth.clients.forEach((authClient) => {
              if (client.name === authClient) {
                newData.push(client);
              }
            });
          });
          data = newData;
        }
        data = orderAlphabeticallyWithAttribute(data, "display_name");
        setClients(data);
        if (client.display_name) {
          const clientFiltered = data.find((x) => x._id === client._id);
          if (client.display_name !== clientFiltered.display_name) {
            setClientSelected(clientFiltered.display_name);
            return;
          }
          setClientSelected(client.display_name);
        } else {
          setClientSelected(data[0].display_name);
          dispatch(setClient(data[0]));
        }
      })
      .catch(() => {
        this.props.enqueueSnackbar(
          "There was an error getting the client names",
          {
            variant: "error",
          },
        );
      });
  };

  const handleClientChange = (e) => {
    setClientSelected(e.target.value);
    clients.forEach((client) => {
      if (client.display_name === e.target.value) {
        dispatch(setClient(client));
        navigate("/");
      }
    });
  };

  return (
    <React.Fragment>
      <AppBar
        position="fixed"
        sx={{
          background: "#4E8CFF",
          minHeight: "90px",
          justifyContent: "center",
          zIndex: "500 !important",
        }}>
        <Toolbar>
          <div
            style={{
              display: "flex",
              flexGrow: 1,
            }}>
            <IconButton
              id="menu-button"
              edge="start"
              sx={{
                marginRight: "4px",
              }}
              onClick={toggleDrawer("left", true)}
              color="inherit"
              aria-label="menu">
              <MenuIcon fontSize="large" />
            </IconButton>
            <Link
              to="/"
              sx={{
                marginLeft: "15px",
              }}>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "800",
                  marginBottom: "-8px",
                  color: "#fff",
                }}>
                Darwin
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "12px",
                  color: "#fff",
                }}>
                by Evoltis
              </Typography>
            </Link>
            {renderComboClient && (
              <Select
                id="combo-clients"
                value={clientSelected}
                defaultValue={clientSelected}
                onChange={(e) => {
                  handleClientChange(e);
                }}
                sx={{
                  marginLeft: "50px",
                  marginRight: "15px",
                  width: "250px",
                  height: "50px",
                  borderRadius: "5px",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
                  backgroundColor: "#fff",
                  color: "#000",
                  fontSize: "17px",
                  "& .MuiSvgIcon-root": {
                    color: "#000",
                  },
                }}>
                {clients.map((client) => (
                  <MenuItem key={client} value={client.display_name}>
                    {client.display_name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </div>
          <Box>
            <Tooltip title={name}>
              <IconButton onClick={handleMenu} sx={{ p: 0 }}>
                <Avatar
                  id="avatar"
                  sx={{
                    color: myTheme.palette.getContrastText(deepPurple[500]),
                    backgroundColor: "#3862ae",
                    width: "60px",
                    height: "60px",
                  }}>
                  {name.toUpperCase().charAt(0)}
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "65px" }}
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(isOpen)}
              onClose={handleClose}>
              {settings.map((setting) =>
                setting !== "logout" ? (
                  <MenuItem
                    id="preferences"
                    component={Link}
                    key={setting}
                    to={"/" + setting.toLocaleLowerCase()}
                    onClick={handleClose}>
                    <ListItemText textalign="center">
                      {t(`home:${setting}`)}
                    </ListItemText>
                  </MenuItem>
                ) : (
                  <MenuItem
                    id="logout"
                    component={Link}
                    key={setting}
                    to={"/"}
                    onClick={() => handleLogout()}>
                    <ListItemIcon>
                      <ExitToAppIcon />
                    </ListItemIcon>
                    <Typography textalign="center">
                      {t(`home:${setting}`)}
                    </Typography>
                  </MenuItem>
                ),
              )}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      {props.enabledOptionsMenu && (
        <div>
          <React.Fragment key={"left"}>
            <SwipeableDrawer
              anchor={"left"}
              open={left}
              onClose={toggleDrawer("left", false)}
              onOpen={toggleDrawer("left", true)}>
              <div
                style={{ width: 250 }}
                role="presentation"
                onClick={toggleDrawer("left", false)}
                onKeyDown={toggleDrawer("left", false)}>
                <List id="menu">
                  <ListItem id="menu-home" button component={Link} to="/">
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("home:home")} id="menu-home-text" />
                  </ListItem>
                  <ListItem
                    id="menu-intents"
                    button
                    component={Link}
                    to="/intents"
                    sx={{
                      display: userPermissions.some(
                        (permission) =>
                          permission.name === "intents" &&
                          permission.fullAccess !== null,
                      )
                        ? ""
                        : "none",
                    }}>
                    <ListItemIcon>
                      <HelpIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("home:intents")} id="menu-intents-text" />
                  </ListItem>
                  <ListItem
                    id="menu-no-match"
                    button
                    component={Link}
                    to="/match"
                    sx={{
                      display: userPermissions.some(
                        (permission) =>
                          permission.name === "noMatch" &&
                          permission.fullAccess !== null,
                      )
                        ? ""
                        : "none",
                    }}>
                    <ListItemIcon>
                      <BlurOffOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("home:match")} id="menu-no-match-text" />
                  </ListItem>
                  <ListItem
                    id="menu-pages"
                    button
                    component={Link}
                    to="/pages"
                    sx={{
                      display: userPermissions.some(
                        (permission) =>
                          permission.name === "pages" &&
                          permission.fullAccess !== null,
                      )
                        ? ""
                        : "none",
                    }}>
                    <ListItemIcon>
                      <LibraryBooksIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("home:pages")} id="menu-pages-text" />
                  </ListItem>
                  <ListItem
                    id="menu-files"
                    button
                    component={Link}
                    to="/files"
                    sx={{
                      display: userPermissions.some(
                        (permission) =>
                          permission.name === "file" &&
                          permission.fullAccess !== null,
                      )
                        ? ""
                        : "none",
                    }}>
                    <ListItemIcon>
                      <FilesIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("home:files")} id="menu-files-text" />
                  </ListItem>
                  <ListItem
                    id="menu-analytics"
                    button
                    component={Link}
                    to="/analytics"
                    sx={{
                      display: userPermissions.some(
                        (permission) =>
                          permission.name === "report" &&
                          permission.fullAccess !== null,
                      )
                        ? ""
                        : "none",
                    }}>
                    <ListItemIcon>
                      <ShowChartIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("home:analytics")} id="menu-analytics-text" />
                  </ListItem>
                  <ListItem
                    id="menu-monitoring"
                    button
                    component={Link}
                    to="/monitoring"
                    sx={{
                      display: userPermissions.some(
                        (permission) =>
                          permission.name === "monitoring" &&
                          permission.fullAccess !== null,
                      )
                        ? ""
                        : "none",
                    }}>
                    <ListItemIcon>
                      <ShowChartIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("home:monitoring")} id="menu-monitoring-text" />
                  </ListItem>
                  <ListItem
                    id="menu-endpoints"
                    button
                    component={Link}
                    to="/endpoints"
                    sx={{
                      display: userPermissions.some(
                        (permission) =>
                          permission.name === "endpoints" &&
                          permission.fullAccess !== null,
                      )
                        ? ""
                        : "none",
                    }}>
                    <ListItemIcon>
                      <TrendingUpIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("home:endpoints")} id="menu-endpoints-text" />
                  </ListItem>
                  <ListItem
                    id="menu-flowgraph"
                    button
                    component={Link}
                    to="/flowgraph"
                    sx={{
                      display: userPermissions.some(
                        (permission) =>
                          permission.name === "flows" &&
                          permission.fullAccess !== null,
                      )
                        ? ""
                        : "none",
                    }}>
                    <ListItemIcon>
                      <AccountTreeIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("home:flow")} id="menu-flowgraph-text" />
                  </ListItem>
                  <ListItem
                    id="menu-agents"
                    button
                    component={Link}
                    to="/agents"
                    sx={{
                      display: userPermissions.some(
                        (permission) =>
                          permission.name === "agents" &&
                          permission.fullAccess !== null,
                      )
                        ? ""
                        : "none",
                    }}>
                    <ListItemIcon>
                      <EmojiPeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("home:agents")} id="menu-agents-text" />
                  </ListItem>
                  <ListItem
                    id="menu-personalization"
                    button
                    component={Link}
                    to="/personalization"
                    sx={{
                      display: userPermissions.some(
                        (permission) =>
                          permission.name === "file" &&
                          permission.fullAccess !== null,
                      )
                        ? ""
                        : "none",
                    }}>
                    <ListItemIcon>
                      <PaletteIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("home:personalizacion")} id="menu-personalization-text" />
                  </ListItem>
                  <ListItem
                    id="menu-working-days"
                    button
                    component={Link}
                    to="/working"
                    sx={{
                      display: userPermissions.some(
                        (permission) =>
                          permission.name === "workingDays" &&
                          permission.fullAccess !== null,
                      )
                        ? ""
                        : "none",
                    }}>
                    <ListItemIcon>
                      <CalendarMonthIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("home:workingdays")} id="menu-working-days-text" />
                  </ListItem>
                  <ListItem
                    id="menu-routes"
                    button
                    component={Link}
                    to="/routes"
                    sx={{
                      display: userPermissions.some(
                        (permission) =>
                          permission.name === "routes" &&
                          permission.fullAccess !== null,
                      )
                        ? ""
                        : "none",
                    }}>
                    <ListItemIcon>
                      <TrendingDownIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("home:routes")} id="menu-routes-text" />
                  </ListItem>
                  <ListItem
                    id="menu-transfer"
                    button
                    component={Link}
                    to="/transferTable"
                    sx={{
                      display: userPermissions.some(
                        (permission) =>
                          permission.name === "chattigoTable" &&
                          permission.fullAccess !== null,
                      )
                        ? ""
                        : "none",
                    }}>
                    <ListItemIcon>
                      <SupportAgentIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("home:transferTable")} id="menu-transfer-text" />
                  </ListItem>{" "}
                  <ListItem
                    id="menu-clients"
                    button
                    component={Link}
                    to="/clients"
                    sx={{
                      display: userPermissions.some(
                        (permission) =>
                          permission.name === "clients" &&
                          permission.fullAccess !== null,
                      )
                        ? ""
                        : "none",
                    }}>
                    <ListItemIcon>
                      <ComputerIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("home:clients")} id="menu-clients-text" />
                  </ListItem>
                  <ListItem
                    id="menu-config"
                    button
                    component={Link}
                    to="/config"
                    sx={{
                      display: userPermissions.some(
                        (permission) =>
                          permission.name === "config" &&
                          permission.fullAccess !== null,
                      )
                        ? ""
                        : "none",
                    }}>
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("home:config")} id="menu-config-text" />
                  </ListItem>
                  <ListItem
                    id="menu-translations"
                    button
                    component={Link}
                    to="/translations"
                    sx={{
                      display: userPermissions.some(
                        (permission) =>
                          permission.name === "translations" &&
                          permission.fullAccess !== null,
                      )
                        ? ""
                        : "none",
                    }}>
                    <ListItemIcon>
                      <TranslateIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("home:translations")} id="menu-translations-text" />
                  </ListItem>
                  <ListItem
                    id="menu-users"
                    button
                    component={Link}
                    to="/users"
                    sx={{
                      display: userPermissions.some(
                        (permission) =>
                          permission.name === "users" &&
                          permission.fullAccess !== null,
                      )
                        ? ""
                        : "none",
                    }}>
                    <ListItemIcon>
                      <GroupIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("home:user")} id="menu-users-text" />
                  </ListItem>
                  <ListItem
                    id="menu-power-bi"
                    button
                    component={Link}
                    to="/powerbi">
                    <ListItemIcon>
                      <GroupIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("home:powerbi")} id="menu-power-bi-text" />
                  </ListItem>
                  <ListItem
                    id="menu-flowgraph-v2"
                    button
                    component={Link}
                    sx={{
                      display: userPermissions.some(
                        (permission) =>
                          permission.name === "flows" &&
                          permission.fullAccess !== null,
                      )
                        ? ""
                        : "none",
                    }}
                    to="/flowgraph-v2">
                    <ListItemIcon>
                      <SchemaIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("Flowgraph 2.0")} id="menu-flowgraph-v2-text" />
                    <Chip
                      label="BETA"
                      sx={{
                        backgroundColor: "#FFA500",
                        color: "#333333",
                        marginLeft: "5px",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        fontSize: "0.7rem",
                      }}
                    />
                  </ListItem>
                  <ListItem
                    id="menu-conversations"
                    button
                    component={Link}
                    sx={{
                      display: userPermissions.some(
                        (permission) =>
                          permission.name === "conversaciones" &&
                          permission.fullAccess !== null,
                      )
                        ? ""
                        : "none",
                    }}
                    to="/conversaciones">
                    <ListItemIcon>
                      <SchemaIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("Conversaciones")} id="menu-conversations-text"  />
                  </ListItem>
                  <ListItem
                    id="menu-ai-training"
                    button
                    component={Link}
                    to="/aiTraining"
                    sx={{
                      display: userPermissions.some(
                        (permission) =>
                          permission.name === "aiTraining" &&
                          permission.fullAccess !== null,
                      )
                        ? ""
                        : "none",
                    }}>
                    <ListItemIcon>
                      <SmartToy />
                    </ListItemIcon>
                    <ListItemText primary={t("home:aiTraining")} id="menu-ai-training-text" />
                  </ListItem>
                </List>
              </div>
            </SwipeableDrawer>
          </React.Fragment>
        </div>
      )}
    </React.Fragment>
  );
};

export default MyAppbar;
