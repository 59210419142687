import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import styles from "../Login.module.css";


const TwoFactorAuth = ({token2Fa, focusedIndex, error2FaModal, handlePaste, handleBackspace, handleInput, handleLogin, handleResendCode, setFocusedIndex, setError2FaModal, setDialogAuth2FA, setToken2Fa}) => {
  const { t } = useTranslation();

  const validate2FaModal = () => {
    let error2FaModal = {};
    if (token2Fa === "" || token2Fa.includes("")) {
      error2FaModal.token2Fa = t("login:validationTokenEmpty");
    }
    return error2FaModal;
  };

  const handleToken2Auth = () => {
    const errorsTemp2Auth = validate2FaModal();
    if (Object.keys(errorsTemp2Auth).length > 0) {
      setError2FaModal(errorsTemp2Auth);
      return;
    }
    handleLogin();
  };
    
  return (
    <div className={styles.content1}>
      <div className={styles.content2}>
        <div className={styles.ttuloDescripcion}>
          <div className={styles.emoji}>✍</div>
          <div className={styles.tituloDescripcion} id="token2fa_title">
            <span>Verifica tu cuenta</span>
          </div>
          <div className={styles.twoFAingresaYVerificaElCdigoQParent}>
            <div className={styles.twoFAingresaYVerifica}>
              Por tu seguridad, enviamos un código a tu correo. Ingrésalo
              aquí para que podamos verificar tu cuenta.
            </div>
          </div>
        </div>
        <div className={styles.twoFAform}>
          <div
            style={{ display: "flex", justifyContent: "space-between" , alignItems: "center" }}>
            {token2Fa.map((value, index) => (
              <React.Fragment key={index}>
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  value={value}
                  onChange={(e) => {
                    handleInput(index, e.target.value);
                    if (e.target.value) {
                      const nextSibling = document.querySelector(
                        `input[name='${index + 1}']`,
                      );
                      if (nextSibling !== null) {
                        nextSibling.focus();
                      }
                    }
                  }}
                  onPaste={handlePaste}
                  onKeyUp={(e) => {
                    if (e.key === "Backspace") handleBackspace(index);
                    if (
                      e.key === "Enter" &&
                      token2Fa.length === 6 &&
                      token2Fa.includes("") === false
                    ) {
                      if (validate2FaModal()) handleLogin();
                    }
                  }}
                  onFocus={() => setFocusedIndex(index)}
                  onBlur={() => setFocusedIndex(null)}
                  className={`${styles.twoFAverifyCode}
                   ${
              focusedIndex !== null && focusedIndex !== index ? styles.blur : ""
              } ${
                error2FaModal ? styles.error : ""
              }`}
                  name={index}
                  id={`#token2fa_${index}`}
                  autoFocus={index === 0}
                />
                {index === 2 && (
                  <div className={styles.separatorToken}></div>
                )}
              </React.Fragment>
            ))}
          </div>
          {error2FaModal ? 
            <div className={styles.helper2FaError} id="2FaError_invalid_token">
              *{error2FaModal.token2Fa}
            </div> 
            : null
          }
          <div className={styles.twoFAnoRecibisteElContainer}>
            <span>{t("login:codeNotReceived")}</span>
            <span                   
              style={{
                marginLeft: "1%",
              }} 
              onClick={handleResendCode}
              className={styles.buttonTypeLink}
              id={"login_resend_token"}>
              {t("login:resend")}
            </span>
          </div>
        </div>
        <div className={styles.twoFAbotForm}>
          <Button
            variant="contained"
            id={"login_button"}
            onClick={() => handleToken2Auth()}
            sx={{
              fontSize: "20px",
              textAlign: "center",
              minWidth: "248px",
              width: "100%",
            }}>
            {t("login:verifyAccount")}
          </Button>
          <a
            id={"return_login"}
            className={styles.buttonTypeLink}
            onClick={() => {
              setToken2Fa(Array(6).fill(""));
              setDialogAuth2FA(false);
              setError2FaModal("");
            }}>
            {t("login:returnLogin")}
          </a>
        </div>
      </div>
    </div>
  );
};
  
export default TwoFactorAuth;