import React, { useState } from "react";
import { Fab, Box, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import styles from "../Home.module.css";

const FabNewScenarioButton = ({ setOpenNewScenario, userPermissions }) => {
  const [hovered, setHovered] = useState(false);
  const { t } = useTranslation("newScenario");

  return (
    <Box
      className={styles.fabBox}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      sx={{
        display: "inline-flex",
        alignItems: "center",
        position: "relative",
      }}>
      <Tooltip
        arrow
        placement="left"
        title={
          !userPermissions ? t("noPermissionTooltip") : null
        }
        PopperProps={{
          modifiers: [{
            name: "offset",
            options: {
              offset: [-30,0],
            },
          }],
        }}
      >
        <span>
          <Fab
            className={styles.fab}
            disabled={!userPermissions}
            onClick={() => setOpenNewScenario(true)}
            sx={{
              width: hovered ? 200 : 45,
              height: 45, // Asegura que el botón sea siempre un círculo o elipse
              borderRadius: hovered ? 5 : 56,
              backgroundColor: "#3A3EB5",
              "&:hover": {
                backgroundColor: "#3A3EB5",
              },
              transition: "width 0.5s, border-radius 0.3s",
              whiteSpace: "nowrap",
              overflow: "hidden",
              display: "flex",
              alignItems: "center", // Centra verticalmente
              justifyContent: "center", // Centra horizontalmente cuando no hay texto
            }}>
            <AddIcon
              sx={{
                color: "#fff",
                transition: "transform 0.5s, mr 0.3s",
                transform: hovered ? "translateX(-20%)" : "translateX(0)", // Ajusta este valor según sea necesario
                mr: hovered ? 1 : 0, // Ajusta el margen derecho para mantener el icono centrado
              }}
            />
            <Box
              component="span"
              sx={{
                display: hovered ? "inline" : "none",
                ml: -1, // Ajusta este valor para equilibrar el espacio si es necesario
                whiteSpace: "nowrap",
              }}>
              {
                t("newScenario:newScenario")
              }
            </Box>
          </Fab>
        </span>
      </Tooltip>
    </Box>
  );
};

export default FabNewScenarioButton;
