import { Button, Dialog } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { post } from "../shared/http/httpService";
import { login as loginAction } from "../shared/redux/authSlice";

import styles from "./Login.module.css";

import { EmailIcon } from "../assets/svgs/darwin-logo";
import { CopyIcon } from "../assets/svgs/darwin-logo";

import Carousel from "./components/Login.Carousel";
import Logo from "./components/Login.Logo";
import LoginForm from "./components/Login.form";
import TwoFactorAuth from "./components/Login.TwoFactorAuth";
import ForgotPassword from "./components/Login.ForgotPassword";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [blockUser, setBlockUser] = useState({});
  const [errors, setErrors] = useState({});
  const [errorModal, setErrorModal] = useState("");
  const [error2FaModal, setError2FaModal] = useState("");
  const [dialogForgotPassword, setDialogForgotPassword] = useState(0);
  const [dialogForgotModal, setDialogForgotModal] = useState(false);
  const [dialogPretoken, setDialogPretoken] = useState(false);
  const [dialogAuth2FA, setDialogAuth2FA] = useState(false);
  const [dialogContactSupport, setDialogContactSupport] = useState(false);
  const [dialogBlockUser, setDialogBlockUser] = useState(false);
  const [dialogResendToken, setDialogResendToken] = useState(false);
  const [modalText, setModalText] = useState({});
  const [token2Fa, setToken2Fa] = useState(Array(6).fill(""));
  const [focusedIndex, setFocusedIndex] = useState(null);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [rememberIp, setRememberIp] = useState(false);
  const { t, i18n } = useTranslation();

  const handleLogin = () => {
    const body = {
      username,
      password,
      token2Fa: token2Fa.join(""),
    };

    if (rememberIp) body.rememberIp = true;

    const errorsTemp = validate();
    if (Object.keys(errorsTemp).length > 0) {
      setErrors(errorsTemp);
      return;
    }
    post("/login", body)
      .then((response) => {
        dispatch(loginAction(response));
        i18n.changeLanguage(response.language);
        navigate("/");
      })
      .catch((errorMessage) => {
        if (errorMessage.status === 401) {
          if (
            errorMessage.message.message !== "Invalid login credentials" &&
            errorMessage.message.message !== "Invalid password"
          ) {
            setDialogPretoken(true);
          } else {
            const errors = {};
            errors.credentials = t("login:invalidLogin");
            setErrors(errors);
          }
          if (errorMessage.message === "Incorrect 2FA Token") {
            let error2FaModal = {};
            error2FaModal.token2Fa = t("login:invalidToken2Fa");
          }
        } else if (errorMessage.status === 429) {
          const dialogModal = {
            tittle: t("login:userBlockedTittle"),
          };
          if (errorMessage.message.loginRetries) {
            dialogModal.text = t("login:userBlockedRetriesText");
            setModalText(dialogModal);
            setDialogBlockUser(true);
          } else {
            dialogModal.text = t("login:userBlockedText");
            setModalText(dialogModal);
            setDialogBlockUser(true);
          }
        } else {
          if (errorMessage.msg === "Invalid two factor token") {
            let error2FaModal = {};
            error2FaModal.token2Fa = t("login:invalidToken2Fa");
            setError2FaModal(error2FaModal);
          }
          if (errorMessage.msg === "Invalid two factor token") {
            let error2FaModal = {};
            error2FaModal.token2Fa = t("login:invalidToken2Fa");
            setError2FaModal(error2FaModal);
          }
        }
      });
  };

  const calculateHours = (time) => {
    const timeNow = Date.now();
    const timeDif = timeNow - time;
    const duration = 60*60*1000;
    const remainingTime = duration - timeDif;

    return Math.max(Math.floor(remainingTime / 1000), 0);
  };

  const recoverPassword = () => {
    const body = {
      email,
    };
    let remainingTime = "";
    post("/login/recoverPassword", body)
      .then(() => {})
      .catch((error) => {
        const errors = {};
        if (error.message && error.status) {
          if (error.status === 429) {
            remainingTime = calculateHours(error.message.timestampFirst);
            setBlockUser({
              value: true,
              time: remainingTime,
            });
          }
          else {
            errors.email = t("login:emailNoExistent");
            setErrors(errors);
          }
        } else
          enqueueSnackbar(t("login:invalidRecoverPassword"), {
            variant: "error",
          });
      })
      .finally(()=>{
        if (remainingTime === ""){
          setDialogForgotModal(true);
        } else {
          setDialogForgotPassword(1);
        }
      });
  };

  useEffect(() => {
    setErrors({});
    setModalText({
      tittle: "",
      text:"",
    });
  }, []);

  useEffect(() => {
    setErrorModal({});
  }, [dialogForgotPassword]);

  useEffect(() => {
    setErrorModal({});
  }, [email]);

  const validate = () => {
    let errors = {};

    if (!username || username === "") {
      errors.username = t("login:validationUserEmpty");
    } 
    if (!password || password === "") {
      errors.password = t("login:validationPasswordEmpty");
    }

    return errors;
  };


  function handleInput(index, value) {
    const values = [...token2Fa];
    values[index] = value;
    setToken2Fa(values);
    setError2FaModal("");
  }

  function handleBackspace(index) {
    const values = [...token2Fa];
    if (index >= 0) {
      values[index] = "";
      setToken2Fa(values);
      setError2FaModal("");
      if (index > 0) {
        const previousInput = document.querySelector(
          `input[name='${index - 1}']`,
        );
        if (previousInput !== null) {
          previousInput.focus();
        }
      }
    }
  }

  function handlePaste(e) {
    const paste = e.clipboardData.getData("text");
    if (paste.length === 6) {
      const values = paste.split("");
      setToken2Fa(values);
    }
  }

  function handleResendCode() {
    const body = {
      username: username,
      password: password,
    };
    post("/login/resendToken2Auth", body)
      .then(() => {
        setDialogResendToken(true);
      });
  }

  return (
    <div className={styles.loginStart}>
      <Carousel />
      <div className={styles.content}>
        <Logo />
        {!dialogAuth2FA && dialogForgotPassword == 0 && (
          <LoginForm
            username={username}
            password={password}
            setDialogContactSupport={setDialogContactSupport}
            errors={errors}
            rememberIp={rememberIp}
            handleLogin={handleLogin}
            setErrors={setErrors}
            setRememberIp={setRememberIp}
            setUsername={setUsername}
            setPassword={setPassword}
            setDialogForgotPassword={setDialogForgotPassword}
          />
        )}
        {dialogAuth2FA && (
          <TwoFactorAuth
            token2Fa={token2Fa}
            focusedIndex={focusedIndex}
            error2FaModal={error2FaModal}
            handlePaste={handlePaste}
            handleBackspace={handleBackspace}
            handleInput={handleInput}
            handleLogin={handleLogin}
            handleResendCode={handleResendCode}
            setFocusedIndex={setFocusedIndex}
            setError2FaModal={setError2FaModal}
            setDialogAuth2FA={setDialogAuth2FA}
            setToken2Fa={setToken2Fa}
          />
        )}
        {dialogForgotPassword > 0 && ( 
          <ForgotPassword
            dialogForgotPassword={dialogForgotPassword}
            setEmail={setEmail}
            recoverPassword={recoverPassword}
            errorModal={errorModal} 
            email={email} 
            blockUser={blockUser} 
            setBlockUser={setBlockUser}
            setDialogForgotPassword={setDialogForgotPassword}
            setErrors={setErrors}
            setErrorModal={setErrorModal}
          />
        )}
      </div>
      <Dialog open={dialogBlockUser} onClose={() => setDialogBlockUser(false)}>
        <div id="dialog-block-user" className={styles.dialogBlockUser}>
          <div className={styles.titleDescription}>
            <EmailIcon className={styles.emailIcon} alt="" />
            <div id="title-block-user" className={styles.headline}>{modalText.tittle}</div>
            <div id="text-block-user" className={styles.supportingText}>
              {modalText.text}
            </div>
          </div>
          <div className={styles.actions}>
            <Button
              id="cancel_button_block_user"
              onClick={() => setDialogBlockUser(false)}
              sx={{
                color: "#2C2F88",
                textTransform: "none",
              }}>
              Cancelar
            </Button>
            <Button
              id="copy_email_button_block_user"
              onClick={() => {
                navigator.clipboard.writeText("clientedarwin@evoltis.com");
              }}
              variant="contained"
              style={{
                textTransform: "none",
                fontSize: "14px",
                width: "152px",
              }}>
              <CopyIcon
                className={styles.copyIcon}
                alt=""
                style={{ marginRight: "10px" }}
              />
              Copiar correo
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog open={dialogContactSupport} onClose={() => setDialogContactSupport(false)}>
        <div className={styles.dialogContactSupport}>
          <div className={styles.titleDescription}>
            <EmailIcon className={styles.emailIcon} alt="" />
            <div className={styles.headline}>Contáctate con soporte</div>
            <div className={styles.supportingText}>
              El equipo de Darwin está para ayudarte con lo que necesites. Envía
              un correo a clientedarwin@evoltis.com con tu consulta.
            </div>
          </div>
          <div className={styles.actions}>
            <Button
              id="cancel-button"
              onClick={() => setDialogContactSupport(false)}
              sx={{
                color: "#2C2F88",
                textTransform: "none",
              }}>
              Cancelar
            </Button>
            <Button
              id="copy-email-button"
              onClick={() => {
                navigator.clipboard.writeText("clientedarwin@evoltis.com");
              }}
              variant="contained"
              style={{
                textTransform: "none",
                fontSize: "14px",
                width: "152px",
              }}>
              <CopyIcon
                className={styles.copyIcon}
                alt=""
                style={{ marginRight: "10px" }}
              />
              Copiar correo
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog open={dialogPretoken} onClose={() => setDialogPretoken(false)}>
        <div className={styles.dialogPretoken} id="token2fa_dialog">
          <div className={styles.titleDescription}>
            <EmailIcon className={styles.emailIcon} alt="" />
            <div className={styles.headline}>Revisa tu correo</div>
            <div className={styles.supportingText}>
              Detectamos que estás iniciando sesión desde una IP desconocida. Te enviamos un código al correo para verificar tu cuenta.
            </div>
          </div>
          <div className={styles.actions}>
            <Button
              onClick={() => {
                setDialogAuth2FA(true);
                setDialogPretoken(false);
              }}
              id="#token2fa_close"
              variant="contained"
              style={{
                textTransform: "none",
                fontSize: "14px",
                width: "152px",
              }}>
              Cerrar
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog open={dialogResendToken} onClose={() => setDialogResendToken(false)}>
        <div className={styles.dialogPretoken}>
          <div className={styles.titleDescription}>
            <EmailIcon className={styles.emailIcon} alt="" />
            <div className={styles.headline}>Revisa tu correo</div>
            <div className={styles.supportingText}>
              Te enviamos un código al correo para verificar tu cuenta.
            </div>
          </div>
          <div className={styles.actions}>
            <Button
              onClick={() => {
                setDialogAuth2FA(true);
                setDialogResendToken(false);
              }}
              id="#token2fa_close"
              variant="contained"
              style={{
                textTransform: "none",
                fontSize: "14px",
                width: "152px",
              }}>
              Cerrar
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog open={dialogForgotModal}>
        <div id="password_recovery_dialog" className={styles.dialogPretoken}>
          <div className={styles.titleDescription}>
            <EmailIcon className={styles.emailIcon} alt= "" />
            <div id="password_recovery_title" className={styles.headline}>Revisa tu correo</div>
            <div id="password_recovery_paragraph" className={styles.supportingText}>
              Te enviamos tu nueva contraseña al correo {email}
            </div>
          </div>
          <div className={styles.twoActions}>
            <Button
              id="password_recovery_modify_email_button"
              onClick={() => {
                setDialogForgotPassword(1);
                setDialogForgotModal(false);
              }}
              sx={{
                color: "#2C2F88",
                textTransform: "none",
              }}>
              Modificar correo
            </Button>
            <Button
              onClick={() => {
                setDialogForgotModal(false);
                setDialogForgotPassword(2);
              }}
              id="password_recovery_close_button"
              variant="contained"
              style={{
                textTransform: "none",
                fontSize: "14px",
                marginLeft:"2%",
                width: "120px",
              }}>
              Continuar
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default Login;
