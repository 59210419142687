import React, { useEffect, useState } from "react";
import {
  Dialog,
  TextField,
  Button,
  Autocomplete,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import styles from "./ScenarioModal.module.css";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import ControlPoint from "@mui/icons-material/ControlPoint";
import { useTranslation } from "react-i18next";
import ChipInput from "../../shared/components/ChipInput";
import { useSnackbar } from "notistack";
import { styled } from "@mui/material/styles";
import { Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { orderAlphabeticallyWithAttribute } from "../../shared/helper/orderAlphabetically";
import { get, post, put } from "../../shared/http/httpService";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/system";

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiAutocomplete-clearIndicator": {
    display: "none",
  },
  "& .MuiAutocomplete-endAdornment button": {
    backgroundColor: "inherit",
  },
  "& .MuiAutocomplete-endAdornment button:hover": {
    backgroundColor: "inherit",
    boxShadow: "none",
  },
  "& .MuiAutocomplete-endAdornment svg": {
    color: "#111",
  },
  "& .MuiInputBase-root": {
    backgroundColor: "rgba(213, 213, 231, 0.2)",
    color: "rgba(213, 213, 231, 0.2)",
    padding: "13px",
  },
  "& .MuiOutlinedInput-root": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderBottomColor: "#7e7e7e", // Default state
      borderRadius: "0px",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderBottomColor: "#7e7e7e", // Hover state
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderBottomColor: "#7e7e7e", // Focused state
    },
  },
});

const ScenarioModal = ({ open, handleClose, scenario }) => {
  const [scenarioName, setScenarioName] = useState("");
  const [scenarioDescription, setScenarioDescription] = useState("");
  const [knowledge, setKnowledge] = useState([]);
  const [scenarioIdentifier, setScenarioIdentifier] = useState("");
  const [hasIdentifier, setHasIdentifier] = useState(false);
  const { t } = useTranslation("newScenario");
  const { enqueueSnackbar } = useSnackbar();
  const [agentNames, setAgentNames] = useState([]);
  let navigate = useNavigate();
  const client = useSelector((state) => state.client);
  const theme = useTheme();

  useEffect(() => {
    if (open) {
      getAgents();
      if (scenario) {
        setHasIdentifier(true);
        setScenarioName(scenario.scenario.display_name);
        setScenarioDescription(scenario.scenario.description);
        setKnowledge(scenario.scenario.knowledge);
        setScenarioIdentifier(scenario.scenario.name);
      }
    }
  }, [open]);

  useEffect(() => {
    if (!hasIdentifier) {
      setScenarioIdentifier(getIdentifier(scenarioName));
    }
    setHasIdentifier(false);
  }, [scenarioName]);

  const getIdentifier = (name) => {
    name = name.replace(/(^\s+)|(\s+$)/g, ""); // trim
    name = name.toLowerCase();

    // remove accents, swap ñ for n, etc
    let from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    let to = "aaaaeeeeiiiioooouuuunc------";
    for (let i = 0, l = from.length; i < l; i++) {
      name = name.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    name = name
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-"); // collapse dashes

    return name;
  };

  const newScenario = () => {
    const body = {
      name: scenarioIdentifier,
      display_name: scenarioName,
      description: scenarioDescription,
      knowledge: knowledge,
    };
    post("/agents", body)
      .then(() => {
        enqueueSnackbar(t("newScenario"), {
          variant: "success",
        });
        navigate("/flowgraph", { state: { scenarioName: scenarioIdentifier } });
      })
      .catch(() => {
        enqueueSnackbar(t("errorNewScenario"), {
          variant: "error",
        });
      });
    handleClose("new", agentNames.length);
  };

  const editScenario = () => {
    const body = {
      _id: scenario.scenario._id,
      agent_name: scenarioIdentifier,
      display_name: scenarioName,
      description: scenarioDescription,
      knowledge: knowledge,
      config_update: false,
      knowledge_update: true,
    };
    put("/agents", body)
      .then(() => {
        enqueueSnackbar(t("newScenario"), {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar(t("errorNewScenario"), {
          variant: "error",
        });
      });
    handleClose("edit");
  };

  const getAgents = () => {
    get("/agents")
      .then(async (data) => {
        let newData = orderAlphabeticallyWithAttribute(data.agents, "name");
        let agentNames = newData.map((agent) => agent.name);
        setAgentNames(agentNames);
      })
      .catch(() => {
        enqueueSnackbar(t("agents:errorGettingAgents"), {
          variant: "error",
        });
      });
  };

  return (
    <Dialog
      PaperProps={{
        className: styles.paper,
      }}
      maxWidth={false}
      open={open}
      onClose={handleClose}>
      <DialogTitle className={styles.modalHeader} variant="new">
        {scenario ? (
          <EditIcon
            fontSize="large"
            style={{ color: "#FF0000" }}
            sx={{
              color: "modalIconButton.color",
            }}></EditIcon>
        ) : (
          <ControlPoint
            fontSize="large"
            sx={{
              color: "modalIconButton.color",
            }}></ControlPoint>
        )}
        <div>
          <span
            className={styles.modalDescription}
            style={{ color: theme.palette.modalTitleText.color }}>
            {t("headerDescripction") + " " + client.name}
          </span>
          <span className={styles.headerTitle}>
            {scenario ? t("editHeaderTitle") : t("newHeaderTitle")}
          </span>
        </div>
        <CloseIcon
          id="scenario-modal-close"
          onClick={handleClose}
          fontSize="large"
          sx={{
            cursor: "pointer",
            color: "modalIconButton.color",
            marginLeft: "auto",
          }}></CloseIcon>
      </DialogTitle>{" "}
      <div
        className={styles.modalDivider}
        style={{ backgroundColor: theme.palette.modalDivider.color }}></div>
      <DialogContent variant="new">
        <span className={styles.modalDescription}>
          {t("contentWelcomeMessage")}
        </span>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
            width: "100%",
          }}>
          <div style={{ flex: 1 }}>
            <span className={styles.modalContentTitle}>
              {t("scenarioName")}
            </span>
            <TextField
              id="scenario-name"
              className={styles.modalTextField}
              placeholder={t("scenarioNameLabel")}
              fullWidth
              margin="normal"
              variant="filled"
              value={scenarioName}
              sx={{
                "& input": {
                  paddingTop: "8px",
                  height: "40px",
                },
              }}
              onChange={(e) => setScenarioName(e.target.value)}
            />
          </div>
          <div style={{ flex: 1 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                gap: "4px",
              }}>
              <span className={styles.modalContentTitle}>
                {t("scenarioIdentifier")}
              </span>
              <Tooltip
                title={t("scenarioIdentifierTooltip")}
                arrow
                placement="right">
                <InfoOutlinedIcon sx={{ color: "modalIconButton.color" }} />
              </Tooltip>
            </div>
            <TextField
              id="scenario-identifier"
              className={styles.modalTextField}
              disabled
              placeholder={t("scenarioIdentifierLabel")}
              fullWidth
              margin="normal"
              variant="filled"
              value={scenarioIdentifier}
              sx={{
                "& .MuiInputBase-input::placeholder": {
                  color: "#1A1A1A",
                  opacity: 1,
                },
                "& input": { paddingTop: "8px", height: "40px" },
                "& .MuiInputBase-input.Mui-disabled": {
                  "-webkit-text-fill-color": "#1A1A1A",
                  opacity: 0.5,
                },
              }}
            />
          </div>
        </div>
        <span className={styles.modalContentTitle}>
          {t("scenarioDescription")}
        </span>
        <TextField
          id="scenario-description"
          className={styles.modalTextField}
          placeholder={t("scenarioDescriptionLabel")}
          fullWidth
          margin="normal"
          variant="filled"
          multiline
          rows={4}
          value={scenarioDescription}
          onChange={(e) => setScenarioDescription(e.target.value)}
        />
        <div
          className={styles.modalContentTitle}
          style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <span className={styles.modalContentTitle}>
            {t("scenarioKnowledge")}
          </span>
          <Tooltip
            title={t("scenarioKnowledgeTooltip")}
            arrow
            placement="right">
            <InfoOutlinedIcon sx={{ color: "modalIconButton.color" }} />
          </Tooltip>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            alignItems: "flex-start",
          }}
          className={styles.knowledge}>
          <div style={{ width: "100%" }}>
            <StyledAutocomplete
              multiple
              inputValue=""
              style={{ height: "100%" }}
              options={agentNames ? agentNames : []}
              value={knowledge ? knowledge : []}
              getOptionLabel={(option) => option}
              renderTags={() => null}
              disableCloseOnSelect
              fullWidth
              blurOnSelect
              renderOption={(props, option, { selected }) => (
                <li
                  {...props}
                  style={{
                    ...props.style,
                    backgroundColor: selected ? "#444" : "transparent",
                  }}>
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  id="scenario-knowledge"
                  {...params}
                  variant="filled"
                  placeholder={t("scenarioKnowledgeLabel")}
                />
              )}
              onInputChange={() => {}}
              onChange={(e, value) => setKnowledge(value)}
              sx={{
                width: "35%",
                marginRight: "1rem",
              }}
            />
          </div>
          <div style={{ width: "100%" }}>
            <span className={styles.chip} style={{ width: "100%" }}>
              <ChipInput
                fontColor="#1a1a1a"
                name="sensitive-params"
                source="params"
                updateProps={(knowledge) => {
                  setKnowledge(knowledge);
                }}
                value={knowledge ? knowledge : []}
                readOnly={true}
              />
            </span>
          </div>
        </div>
      </DialogContent>
      <DialogActions
        variant="new"
        className={styles.modalFooter}
        style={{
          flexDirection: "column",
          alignItems: "flex-start",
        }}>
        <div>
          <span className={styles.helperMessage}>
            {t("contentFooterMessage")}
          </span>
        </div>
        <div
          className={styles.dialogActions}
          style={{ alignSelf: "end", marginTop: "20px" }}>
          <Button
            id="edit-cancel-button"
            onClick={handleClose}
            variant="outlined"
            sx={{
              width: "152px",
              color: "#2C2F88",
              marginRight: "8px",
            }}>
            {t("cancelButton")}
          </Button>
          <Button
            id="edit-save-button"
            onClick={scenario ? editScenario : newScenario}
            variant="contained"
            sx={{
              width: "152px",
            }}
            color="primary">
            {t("saveButton")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ScenarioModal;
